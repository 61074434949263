<template>
    <div>
        <div style="position: relative" v-if="!planCheck && getted">
            <div class="d-flex flex-1 blured-div">
            <div class="wrapper">
                <div class="text-center">
                <h1 class="d-sm-none d-block">
                    Unlock Keyword Tracking with an Upgrade
                </h1>
                <h1 style="font-size: 3rem" class="d-none d-sm-block">
                    Unlock Keyword Tracking with an Upgrade
                </h1>
                <h4>
                    When you upgrade to Pro, you will be able to access keyword
                    Tracking, niches, and more tools from AppLead.
                </h4>
                </div>
                <b-button
                style="font-size: 2rem"
                variant="outline-success"
                to="/pages/plan-selection"
                >
                Upgrade Now
                </b-button>
            </div>
            </div>
        </div>
        <div v-if="!getted">
            <div class="d-flex justify-content-center align-items-center text-center" style="height: 80vh;" v-if="isLoading == false">
                <b-spinner style="width: 5rem; height: 5rem;"  variant="primary" label="Spinning"></b-spinner>
            </div>
        </div>
        <div v-if="planCheck">
            <div v-if="isLoading && !noData">
                <div class="d-flex">
                    <h3 style="margin-top: 7px;">Apps Tracking</h3>
                    <b-button 
                        v-b-tooltip.hover
                        title="Add Keyword To Track it"
                        class="ml-auto d-flex"
                        variant="outline-success"
                        to="/add-keyword-tracking"
                    >
                        <feather-icon icon="PlusCircleIcon" size="18" />
                        <span class="add-keyword-span">Add Keyword</span>
                    </b-button>
                </div>
                <div>
                    <b-modal
                        id="modal-danger-for-delete-keyword"
                        ok-variant="danger"
                        ok-title="Confirm"
                        modal-class="modal-danger"
                        centered
                        title="Delete"
                        @ok="destroyTrackedAppKeyword(deleteData)"
                    >
                        <b-card-text>
                            Are you sure you want to delete this keyword ?
                        </b-card-text>
                    </b-modal>
                    <b-modal id="chart-modal" centered size="lg" hide-footer>
                        <template #modal-header>
                            <div class="d-flex w-100 align-items-center justify-content-between">
                                <h6 class="mb-0">Tracking Chart : {{ popUpTitle }}</h6>
                                <b-button @click="closeChartPopUp" variant="outline-danger">
                                    <feather-icon icon="XIcon" size="18" />
                                </b-button>
                            </div>

                        </template>

                        <div>
                            <div v-if="isChartLoading">
                                <b-container>
                                    <b-row>
                                        <b-col lg="5" md="6" sm="12" class="px-lg-0 pl-md-0 px-sm-0">
                                            <label>From :</label>
                                            <b-form-datepicker v-model="startDate" locale="en" :max="endDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                                        </b-col >
                                        <b-col lg="5" md="6" sm="12" class="pr-lg-0 pl-md-1 px-sm-0">
                                            <label>To :</label>
                                            <b-form-datepicker v-model="endDate" locale="en" :min="startDate" :max="currentDate" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" ></b-form-datepicker>
                                        </b-col>
                                        <b-col lg="2" md="12" sm="12"  class="d-flex flex-column pr-lg-0 pl-lg-1 px-sm-0">
                                            <b-button @click="trackedAppsSeach" variant="primary" style="margin-top: 23px; padding-top:13px;">Search</b-button>
                                        </b-col>                 
                                    </b-row>
                                </b-container>
                                <div class="chart-dev" v-if="keywordNoData">
                                    <VueApexCharts class="mt-1" ref="chart"  width="760" height="300" type="line" :options="chartOptions" :series="chartSeries"></VueApexCharts>
                                </div>
                                <div class="d-flex  align-items-center justify-content-center" style="width: 100%; height: 300px;" v-else>
                                    <h5>No information available for the selected date range.</h5>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center align-items-center text-center" style="height: 300px;" v-if="isChartLoading == false">
                                <b-spinner style="width: 5rem; height: 5rem;"  variant="primary" label="Spinning"></b-spinner>
                            </div>
                        </div>

                    </b-modal>
                    <b-card-group deck class="flex-column">
                        <b-card v-for="(app,key) in trackedApps" :key="key" class="mt-1" :title="app.title" header-tag="header" footer-tag="footer">
                            <div class="keywords-div">
                                <div v-for="(keyword,keywordKey) in app.keywords" :key="keywordKey">            
                                    <div class="p-1 keyword-div">
                                            <h6 class="d-flex align-items-center mb-0">{{ Object.keys(keyword)[1]  }}</h6>
                                            <span>
                                                <b-button @click="showDeleteKeywordPopUp(app.appId,keyword.id)" v-b-tooltip.hover title="Delete Keyword" class="mx-1" variant="outline-danger">
                                                    <feather-icon icon="Trash2Icon" size="18" />
                                                </b-button>
                                                <b-button @click="showChartPopUp(Object.keys(keyword)[1],app.title)" v-b-tooltip.hover title="Tracking Chart" variant="outline-info">
                                                    <feather-icon icon="BarChart2Icon" size="18" />
                                                </b-button>
                                            </span>
                                    </div>
                                    <hr class="w-75 my-0 keywords-hr" v-if="keywordKey !== app.keywords.length - 1" >
                                </div>
                            </div>             
                        </b-card>
                    </b-card-group>
                </div>
            </div>
            <div class="text-center" v-if="noData">
                <div>
                    <div class="d-flex">
                        <b-button 
                            v-b-tooltip.hover
                            title="Add Keyword To Track it"
                            class="ml-auto d-flex"
                            variant="outline-success"
                            to="/add-keyword-tracking"
                        >
                            <feather-icon icon="PlusCircleIcon" size="18" />
                            <span class="add-keyword-span">Add Keyword</span>
                        </b-button>
                    </div>
                    <div class="nodataimg">
                        <img :src="noDataImg" id="no-data" />
                        <h1>You are not currently tracking any keywords.</h1> 
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center text-center" style="height: 80vh;" v-if="isLoading == false">
                <b-spinner style="width: 5rem; height: 5rem;"  variant="primary" label="Spinning"></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import noDataImg from "@/assets/images/NoData.png";
import { BCardGroup, BCard, BCardText, BButton, VBTooltip, BModal, BSpinner, BCalendar, BFormDatepicker, BRow, BCol, BContainer } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts'
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default{
    components:{
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    VBTooltip,
    BModal,
    VueApexCharts,
    BSpinner,
    BCalendar,
    BFormDatepicker,
    BRow,
    BCol,
    BContainer,
},
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    mounted(){
        this.getUserTrackedApps()
        this.checkUserPlan();
    },
    methods:{
        showChartPopUp(currentKeyword,appTitle){
            this.searchAppTitle = appTitle;
            const theme = localStorage.getItem('vuexy-skin');
            this.chartOptions.theme.mode = theme ;
            this.popUpTitle = currentKeyword;
            this.$root.$emit("bv::show::modal", "chart-modal");
            this.isChartLoading = false
            this.keywordNoData = false
            axios.post('/tracked-apps',{
                startDate:this.startDate,
                endDate:this.endDate
            }).then(({data})=>{
                this.isChartLoading = true
                if(data.message == 'Tracked keywords not found'){
                    this.noData = true
                }
                if(data.data){
                    this.noData = false
                    this.trackedApps = data.data
                    const currentApp = data.data.find(elem => elem.title === appTitle);

                    const keywordData = currentApp.keywords.find(elem => currentKeyword in elem);
                    if(keywordData[currentKeyword] == 'No information available for the selected date range.'){
                        this.keywordNoData = false
                    }else{
                        this.keywordNoData = true
                        this.chartOptions.xaxis.categories = keywordData[currentKeyword].map((item) => item.date);
                        this.chartSeries[0].data = keywordData[currentKeyword].map((data) => data.position);
                        // setTimeout(()=>{
                        //     const resetBtn = document.querySelector(".apexcharts-reset-icon");
                        //     resetBtn.click();
                        // },800)
                    }         
                }
            })
        },
        
        trackedAppsSeach(){
            this.isChartLoading = false
            this.keywordNoData = false
            axios.post('/tracked-apps',{
                startDate:this.startDate,
                endDate:this.endDate
            }).then(({data})=>{
                this.isChartLoading = true
                if(data.message == 'Tracked keywords not found'){
                    this.noData = true
                }
                if(data.data){
                    this.noData = false
                    this.trackedApps = data.data
                    const currentApp = data.data.find(elem => elem.title === this.searchAppTitle);

                    const keywordData = currentApp.keywords.find(elem => this.popUpTitle in elem);
                    if(keywordData[this.popUpTitle] == 'No information available for the selected date range.'){
                        this.keywordNoData = false
                    }else{
                        this.keywordNoData = true
                        this.chartOptions.xaxis.categories = keywordData[this.popUpTitle].map((item) => item.date);
                        this.chartSeries[0].data = keywordData[this.popUpTitle].map((data) => data.position);
                        // setTimeout(()=>{
                        //     const resetBtn = document.querySelector(".apexcharts-reset-icon");
                        //     resetBtn.click();
                        // },800)
                    }         
                }
            })
        },
        closeChartPopUp(){
            this.$root.$emit("bv::hide::modal", "chart-modal");
        },
        showDeleteKeywordPopUp(appId,keywordId){
            this.deleteData = { appId, keywordId };
            this.$root.$emit("bv::show::modal", "modal-danger-for-delete-keyword",);
        },
        getUserTrackedApps(){
            this.isLoading = false
            axios.post('/tracked-apps',{
                startDate:this.startDate,
                endDate:this.endDate
            }).then(({data})=>{
                this.isLoading = true
                if(data.message == 'Tracked keywords not found'){
                    this.noData = true
                }
                if(data.data){
                    this.noData = false
                    this.trackedApps = data.data
                    
                }else{
                    this.noData = true
                    this.trackedApps = [];
                }
            })
        },
        getCurrentDate() {
            const today = new Date();
            return today.toISOString().split("T")[0];
        },
        calculateStartDate() {
            const weekBefore = new Date();
            weekBefore.setDate(weekBefore.getDate() - 7);
            return weekBefore.toISOString().split("T")[0];
        },
        calculateEndDate() {
            const today = new Date();
            return today.toISOString().split("T")[0];
        },
        destroyTrackedAppKeyword(){
            const { appId, keywordId } = this.deleteData;
            if(this.deleteData && appId && keywordId){
                axios.post("/destroy-tracked-app-keyword",{appId:appId,keywordId:keywordId})
                .then(({data})=>{
                    if(data.status == "success"){
                        this.showToast("success", "CheckIcon", "success",data.message)
                        this.getUserTrackedApps()
                    }else{
                        this.showToast(data.status, "AlertTriangleIcon", data.status ,data.message)
                    }
                })
            }        
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title,
                icon,
                text,
                variant,
                },
            });
        },
        checkUserPlan() {
            axios.post("/checkUserSubscription")
            .then(({ data }) => {
                this.getted = true;
                this.planCheck = true;
            }).catch((err) => {
                this.planCheck = false;
                this.getted = true;
            });
        },
    },
    data(){
        return{
            noDataImg,
            popUpTitle: "",
            startDate: this.calculateStartDate(),
            endDate: this.calculateEndDate(),
            currentDate: this.getCurrentDate(), 
            trackedApps: [],
            isLoading: false,
            isChartLoading:false,
            noData: false,
            keywordNoData:false, 
            searchAppTitle:null,
            getted: false,
            planCheck: null,
            chartOptions: {
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                        }
                    },
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    reversed: true,
                    min: 1,
                    max: 21,
                    tickAmount: 10,
                    labels: {
                        formatter: function (val) {
                            if(val === 21){
                                return '+ 20';
                            }else if (val !== 0) {
                                return Math.round(val);
                            } else {
                                return Math.round(val);
                            }
                        }
                    }
                },
                stroke: {
                    curve: 'smooth',
                },
                theme: {
                    mode: 'light', // or 'dark'
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
                colors: ['#167CC9'],
            },
            chartSeries: [
                {
                    name: 'Position',
                    data: [],
                },
            ],
        }
    }
}
</script>
<style>

.apexcharts-menu-item {
    padding: 8px 0px !important;
    text-align: center;
}
.apexcharts-menu-item:hover{
    background-color: #167DCB;
}
</style>
<style scoped>
.card-body{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
.blured-div {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.192);
  opacity: 0.;
  width: 100% !important;
  height: 80vh !important;
  z-index: 100;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
}
#no-data {
  height: 100%;
  max-height: 800px;
  max-width: 400px;
  width: 100%;
}
.nodataimg {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.chart-dev {
    overflow: hidden;
    overflow-x: auto;
}

.chart-dev::-webkit-scrollbar {
  height: 8px !important;
}

.chart-dev::-webkit-scrollbar-thumb {
  background-color: #167DCB !important;
}
.chart-dev::-webkit-scrollbar-thumb:hover{
  background-color: #0e70bb !important;
}
.chart-dev:active::-webkit-scrollbar-thumb{
  background-color: #015a9e !important;
}
.chart-dev::-webkit-scrollbar-track {
  background-color: #c2c2c2a6 !important;
}
.chart-dev {
  scrollbar-width: thin !important;
  scrollbar-color: #167DCB #c2c2c2a6 !important;
}
.keywords-hr{
    border-width: 1px;
    border-color: #afafaf3f;
}
.add-keyword-span{
    margin-left: 5px;
    padding-top: 2px;
}
.card-body{
    display: flex;
}
.keyword-div{
    height: 69px; 
    display: flex;
    justify-content: space-between;
}
.keywords-div{
    width: 70%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}
.card-body > h4{
    width: 30%;
}
.card-title{
    margin-bottom: 16px !important;
    margin-top: 16px !important;
    border-right: 1px solid #afafaf62;
    display: flex;
    align-items: center;
}
@media (max-width: 500px){
    .card-body{
        display: block !important;
    }
    .card-title{
        border-right: none;
        border-bottom: 1px solid #afafaf9d;
        padding-bottom: 16px;
    }
    .card-body > h4{
    width: 100%;
    }   
    .keywords-div{
        width: 100%;
    }
}
</style>